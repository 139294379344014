import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import './InstaCard.scss';
const InstaCard = ({ insta, onClick }) => {
    const formatDuration = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(remainingSeconds).padStart(2, '0');
        return `${formattedMinutes}:${formattedSeconds}`;
    };
    return (_jsxs("div", { className: "carousel-cell ds-instaWidgetCard", onClick: onClick, children: [_jsx("img", { src: insta.thumbnails.shorts[0], alt: insta.title, loading: "lazy", className: "ds-instaWidgetCard__thumbnail" }), _jsxs("div", { className: "ds-instaWidgetCard__text", children: [_jsx("b", { children: insta.title }), _jsx("span", { children: formatDuration(insta.duration) })] }), _jsx("i", { className: "videoPlayButton" })] }));
};
export default InstaCard;
