import { jsx as _jsx } from "react/jsx-runtime";
import ReactDom from 'react-dom/client';
import App from './App';
import hidePreloader from '../common/utils/hidePreloader';
function renderApp() {
    const el = document.querySelector('react-instaWidget');
    if (!el) {
        throw new Error('Element with selector "react-instaWidget" not found');
    }
    const fetchUrl = el.getAttribute('data-fetch');
    const hideHeader = Boolean(el.getAttribute('data-hide-header'));
    if (!fetchUrl) {
        throw new Error('Fetch URL is not provided');
    }
    fetch(fetchUrl, {
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.json())
        .then((endpointData) => {
        var _a;
        if (endpointData.length > 0) {
            const scriptTag = el === null || el === void 0 ? void 0 : el.querySelector('script[type="application/json"]');
            if (!scriptTag) {
                throw new Error('Inline JSON script tag is not found');
            }
            const inlineJson = (_a = scriptTag.textContent) !== null && _a !== void 0 ? _a : '';
            const inlineJsonData = JSON.parse(inlineJson);
            const root = ReactDom.createRoot(el);
            root.render(_jsx(App, { endpointData: endpointData, inlineJsonData: inlineJsonData, hideHeader: hideHeader }));
        }
    })
        .catch((error) => {
        throw new Error('Error fetching data: ' + error);
    })
        .finally(() => {
        hidePreloader(el);
    });
}
renderApp();
