import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect, useRef } from 'react';
import './InstaModal.scss';
import getYouTubeEmbedURL from '../../utils/constants'; // Adjust the path as necessary
const InstaModal = ({ insta, onClose, onNext, onPrev, showInstaModal, isMobile, }) => {
    const dialogRef = useRef(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    useEffect(() => {
        var _a, _b;
        if (showInstaModal) {
            (_a = dialogRef.current) === null || _a === void 0 ? void 0 : _a.showModal();
        }
        else {
            (_b = dialogRef.current) === null || _b === void 0 ? void 0 : _b.close();
        }
    }, [showInstaModal]);
    const calculateDimensions = () => {
        const aspectRatio = 315 / 560;
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;
        const maxWidth = isMobile ? windowWidth : windowWidth * 0.9;
        const maxHeight = isMobile ? windowHeight - 80 : windowHeight * 0.9;
        let width = maxWidth;
        let height = width / aspectRatio;
        if (height > maxHeight) {
            height = maxHeight;
            width = height * aspectRatio;
        }
        setDimensions({ width, height });
    };
    useEffect(() => {
        calculateDimensions();
        const resizeObserver = new ResizeObserver((entries) => {
            entries.forEach(() => {
                calculateDimensions();
            });
        });
        resizeObserver.observe(document.body);
        return () => {
            resizeObserver.disconnect();
        };
    }, [isMobile]);
    const handleBackdropClick = (event) => {
        if (event.target === dialogRef.current) {
            onClose();
        }
    };
    return (_jsx("dialog", { className: "ds-instaModal", ref: dialogRef, onClick: handleBackdropClick, children: _jsxs("div", { className: "ds-instaModal__frame", children: [_jsx("iframe", { title: insta.title, width: dimensions.width, height: dimensions.height, src: getYouTubeEmbedURL(insta.id), allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture", allowFullScreen: true, className: "ds-instaModal__embed" }), _jsx("button", { type: "button", onClick: onClose, className: "ds-instaModal__close" }), _jsx("button", { type: "button", onClick: onPrev, className: "ds-instaModal__prev", "aria-label": "Previous" }), _jsx("button", { type: "button", onClick: onNext, className: "ds-instaModal__next", "aria-label": "Next" })] }) }));
};
export default InstaModal;
