import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useTranslation } from 'react-i18next';
import Carousel from './components/Carousel/Carousel';
import i18n from '../common/utils/i18n';
const App = ({ endpointData, inlineJsonData, hideHeader }) => {
    i18n.addResourceBundle('en', 'translation', inlineJsonData.translations);
    const { t } = useTranslation();
    return (_jsxs("div", { className: "ds-section", children: [!hideHeader && (_jsx("h2", { children: t('shortVideos') })), _jsx(Carousel, { data: endpointData })] }));
};
export default App;
